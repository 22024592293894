<template>
  <div>
    <app-header />

    <main v-if="! loading">
      <div
        class="
          uk-section uk-padding-remove-vertical uk-margin-remove-vertical
          in-equity-breadcrumb
        "
      >
        <div class="uk-container">
          <div class="uk-grid">
            <div class="uk-width-1-1">
              <ul class="uk-breadcrumb">
                <li>
                  <router-link :to="{ name: 'siteadmin.public.home' }">Home</router-link>
                </li>
                <li><span>Top Brokers</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-section">
        <div
          class="uk-container uk-margin-top uk-margin-medium-bottom"
        >
          <div
            class="uk-grid uk-child-width-1-2@m in-testimonial-2"
            data-uk-grid=""
          >
            <div class="uk-width-1-1@m uk-text-center uk-first-column">
              <h1 class="in-highlight">
                <textarea rows="2" style="font-size: 40px;" class="uk-textarea" v-model="intro.title"></textarea>
              </h1>
              <editor v-model="intro.content" height="300" />
            </div>
          </div>
          <div
            class="uk-grid"
            v-for="(broker, i) in brokers"
            :key="`broker-${i}`"
          >
            <div class="uk-width-1-1">
              <div
                :class="`uk-card uk-card-${
                  i % 2 == 0 ? 'secondary' : 'primary'
                }`"
              >
                <div class="uk-card-body">
                  <div
                    class="
                      uk-grid-divider
                      uk-child-width-1-1
                      uk-child-width-1-3@m
                      uk-grid
                    "
                  >
                    <div class="uk-first-column">
                      <img
                        :src="broker.logo"
                        :alt="broker.title"
                        style="height: 100px"
                      />
                      <h2 class="mb-0">{{ broker.title }}</h2>
                      <p>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        4.8
                      </p>
                      <a
                        :href="broker.link"
                        target="_blank"
                        class="uk-button uk-button-primary bg-red uk-margin-top"
                        >Start Trading NOW!</a
                      >
                    </div>
                    <div>
                      <ul>
                        <li
                          class="text-bold"
                          v-for="(feature, j) in broker.features"
                          :key="`feature-${i}-${j}`"
                        >
                          {{ feature }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="
          uk-section uk-section-primary uk-preserve-color
          in-equity-6
          uk-background-contain uk-background-center
        "
        style="background-image: url('/new/in-decor-2.svg')"
      >
        <getting-started
          :loading="loading"
          :getting_started="getting_started"
        />
      </div>
    </main>

    <loading v-if="loading" />

    <app-footer />
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import GettingStarted from "../shared/GettingStarted";

export default {
  components: {
    AppHeader,
    AppFooter,
    GettingStarted
  },

  mounted() {
    this.fetch()
  },

  data() {
    return {
      loading: true,
      intro: {},
      brokers: [],
      getting_started: {},
      disclaimer: ""
    }
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/sitecontent/public-top-brokers").then((response) => {
        this.intro = response.data.intro;
        this.brokers = response.data.brokers;
        this.getting_started = response.data.getting_started;
        this.disclaimer = response.data.disclaimer;
        this.loading = false;
      });
    }
  }
};
</script>
